:root {
    --main-white: #fff;
    --selected-color: #1B698E;
    --main-border: #d1d5db85;
}

.admin-user-list-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.admin-page-title-main .admin-page-title {
    color: #111827;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

.main-btn-sec .btn.btn-primary {
    background-color: var(--primary);
    color: var(--white);
    min-width: unset;
    border-radius: var(--radius6);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 1px solid transparent;
    padding: 10px 20px;
    margin-left: 10px;
}

.main-btn-sec .btn.btn-secondary {
    background-color: transparent;
    color: var(--primary);
    min-width: unset;
    border-radius: var(--radius6);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 1px solid var(--primary);
    padding: 10px 20px;
}

.admin-main .btn {
    padding: 0;
    min-width: unset;
}

.admin-main .form-group .form-lable {
    color: #989898;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 6px;
    margin-top: 0;
}

.admin-main .form-group .form-input input {
    background-color: transparent;
    border-radius: var(--radius6);
    font-size: 14px;
    line-height: 18px;
    padding: 10px 15px;
}

.admin-main .form-group .form-input fieldset,
.admin-main .form-group .form-input .MuiOutlinedInput-root:hover fieldset {
    border: 1px solid var(--secondary);
}

.admin-main .form-group .form-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border: 1px solid var(--primary);
}

.admin-main .form-group .form-checkbox {
    margin: 0;
    gap: 5px;
    align-items: flex-start;
}

.admin-main .form-group .form-checkbox .MuiCheckbox-root {
    padding: 0;
}

.admin-main .form-group .form-checkbox .MuiSvgIcon-root {
    color: var(--primary);
    font-size: 20px;
}

.admin-main .form-group .form-checkbox .MuiTypography-root {
    font-size: 14px;
    line-height: 18px;
    color: #989898;
}

.admin-main .dahboard-common-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.admin-main .dahboard-common-header .header-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
}

/* start common table design  */

.admin-table-data-flex {
    display: flex;
    align-items: center;
    width: 100%;
}

.admin-table-data-btn-flex {
    display: flex;
}

.admin-table-data-btn-flex .admin-table-data-btn {
    min-width: 30px;
}

.admin-table-data-img {
    margin-right: 15px;
    height: 25px;
    width: 25px;
    object-fit: cover;
    border-radius: 5px;
}

.admin-table-data-flex .admin-table-data-text {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-size: 12px;
    line-height: 18px;
    color: var(--secondary-color);
    font-weight: 400;
}

.page-table-main .table-container {
    padding-bottom: 7px;
    background-color: transparent;
    box-shadow: none;
    overflow: auto;
    max-width: calc(100vw - 0px);
}

/* .page-table-main .table {
    min-width: 1100px;
} */

.page-table-main .table-th {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-color);
    font-weight: 500;
    padding: 10px 10px 10px 10px;
    border-bottom: 1px solid var(--main-border);
    background-color: #1b698e1f;
}

.page-table-main .table-td {
    font-size: 12px;
    line-height: 18px;
    color: var(--secondary-color);
    font-weight: 400;
    padding: 10px 10px 10px 10px;
    border-bottom: 1px solid var(--main-border);
    border: 0;
}

.page-table-main .table-container::-webkit-scrollbar {
    width: 2px;
    height: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-track {
    background: var(--main-border);
    border-radius: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-thumb {
    background: #8888885c;
}

.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* end common table design  */
/* start pagination css */

.admin-pagination-main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* border-top: 1px solid var(--main-border); */
    padding-top: 20px;
}

.admin-pagination-main .MuiPagination-ul {
    margin-right: 20px;
}

.admin-pagination-main .MuiPaginationItem-root {
    height: 20px;
    min-width: 20px;
    border-radius: 4px;
    padding: 0px;
    font-size: 12px;
    line-height: 18px;
    color: var(--secondary-color);
    font-weight: 400;
    margin: 0 4px;
}

.admin-pagination-main .MuiPaginationItem-root.Mui-selected:hover {
    background-color: var(--selected-color);
    color: var(--main-white);
}

.admin-pagination-main .MuiPaginationItem-root.Mui-selected {
    background-color: var(--primary);
    color: var(--main-white);
}

/* end pagination css */
.common-card {
    -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), .03);
    box-shadow: 0 5px 20px rgba(173, 181, 217, .03);
    background-color: var(--main-white);
    border: 1px solid var(--main-border);
    border-radius: 10px;
    padding: 20px;
}

.admin-icon {
    height: 15px;
    width: 15px;
    object-fit: contain;
}

.admin-table-data-btn-flex .metamask-icon {
    height: 23px;
    width: 23px;
}

/* start input field design css */

.admin-form-group .admin-form-control input {
    border: 1px solid var(--main-border);
    border-radius: 4px;
    width: 100%;
    padding: 10px 15px;
    background-color: transparent;
    color: var(--secondary-color);
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
}

.admin-form-group .admin-form-control input::placeholder {
    color: hsl(0, 0%, 50%);
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    opacity: 1;
}

.admin-form-group .admin-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border-width: 0px;
}

.admin-form-control fieldset {
    border: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--main-border) !important;
}

.admin-form-control-textarea:focus-visible {
    outline: none !important;
}

/* end input field design css */
/* password field */

.admin-input-box .admin-form-lable {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin: 0px 0px 6px;
    color: var(--secondary-color);
}

/* .admin-input-box {
    margin-bottom: 20px;
} */

.admin-input-box .MuiInputBase-root {
    width: 100%;
}

.admin-input-box .MuiInputBase-root fieldset:hover {
    border-color: transparent;
}

.admin-form-group .admin-form-control-eye {
    border: 1px solid var(--main-border);
}

.admin-input-box .admin-form-control-eye input {
    border: 0px;
}

.admin-input-box .admin-form-control-eye input::placeholder {
    color: hsl(0, 0%, 50%);
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    opacity: 1;
}

/* End password field */
.upload-btn {
    min-height: 38px;
    background-color: var(--primary);
}

.mt-15 {
    margin-top: 15px;
}

/* / start dropdown css / */
.cus-autocomplete .MuiInputBase-fullWidth {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.cus-autocomplete input::placeholder {
    color: hsl(0, 0%, 50%);
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    opacity: 1;
}

.import-steps-modal .MuiPaper-elevation {
    border-radius: 10px;
    padding: 30px;
    max-width: 500px;
}

.import-steps-modal .modal-title {
    padding: 0px 0px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.import-steps-modal .modal-body {
    padding: 0px 0px;
}

.modal-body .modal-content span {
    font-weight: 600;
    /* color: #111827; */
    font-size: 16px;
}

.modal-content p {
    margin-bottom: 10px;
    font-size: 14px;
}

/* / end dropdown css / */
/* .main-btn-sec .main-btn,
.main-btn-sec .main-btn:hover{
    background: var(--primary);
    color: #FFF;
    font-size: 14px;
    text-transform: capitalize;
    border-radius: 5px;
    padding: 8px 23px;
} */
.p-0 {
    padding: 0 !important;
}

.add-notary-card {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.cus-textarea {
    resize: none;
    width: 100% !important;
    padding: 10px 15px;
    border: 1px solid var(--main-border);
    outline: none;
    min-height: 100px;
    border-radius: 4px;
}

.cus-textarea::placeholder {
    color: hsl(0, 0%, 50%);
    font-size: 12px;
    line-height: 20px;
    opacity: 1;
}

.close-btn-sec {
    cursor: pointer;
}

/* .add-notary-modal .main-btn-sec{
margin-top: 20px;
} */
.main-btn-sec {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.add-notary-modal .modal-body {
    max-height: 400px;
    overflow: auto;
}

.modal-cus-check .MuiFormControlLabel-label {
    font-size: 14px;
}

.confirm-upload-modal .MuiPaper-elevation {
    max-width: 400px;
}

.upload-btn {
    background-color: var(--primary) !important;
}

.auth-body .main-btn-sec {
    margin-top: 0;
}

.auth-body .main-btn-sec .btn-primary {
    margin-left: 0;
    width: 100%;
    /* margin-bottom: 16px; */
}

.mb-16 {
    margin-bottom: 16px;
}

.loader-main {
    position: absolute;
    top: 0;
    background: rgb(0 0 0 / 50%);
    left: 0;
    right: 0;
    bottom: 0;
}

.loader-sec {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.popup-container {
    position: relative;
    display: inline-block;
}

.popup {
    position: absolute;
    background-color: #f9f9f9;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
}

.popup-container:hover .popup {
    display: block;
}

.popup-container .popup {
    display: none;
}