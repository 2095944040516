:root {
  --black: #000;
  --white: #fff;
  --background: #f7fafe;
  --primary: #27447c;
  --secondary: #e4e4e4;
  --radius12: 12px;
  --radius6: 6px;
  --shadow-sm: 0 0 3px 3px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Thin.woff2") format("woff2"),
    url("../fonts/inter/Inter-Thin.woff") format("woff"),
    url("../fonts/inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-ExtraLight.woff2") format("woff2"),
    url("../fonts/inter/Inter-ExtraLight.woff") format("woff"),
    url("../fonts/inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Light.woff2") format("woff2"),
    url("../fonts/inter/Inter-Light.woff") format("woff"),
    url("../fonts/inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Regular.woff2") format("woff2"),
    url("../fonts/inter/Inter-Regular.woff") format("woff"),
    url("../fonts/inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Medium.woff2") format("woff2"),
    url("../fonts/inter/Inter-Medium.woff") format("woff"),
    url("../fonts/inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-SemiBold.woff2") format("woff2"),
    url("../fonts/inter/Inter-SemiBold.woff") format("woff"),
    url("../fonts/inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Bold.woff2") format("woff2"),
    url("../fonts/inter/Inter-Bold.woff") format("woff"),
    url("../fonts/inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-ExtraBold.woff2") format("woff2"),
    url("../fonts/inter/Inter-ExtraBold.woff") format("woff"),
    url("../fonts/inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Black.woff2") format("woff2"),
    url("../fonts/inter/Inter-Black.woff") format("woff"),
    url("../fonts/inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter", "sans-serif";
  color: #000;
}

ol,
ul {
  padding: 0;
  list-style: none;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.cus-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 16px;
}

.cus-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #3d60a5;
  border-radius: 100rem;
}

.cus-scrollbar::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 100rem;
}

.cus-scrollbar {
  scrollbar-color: #e4e4e4 #3d60a5;
  scrollbar-width: thin;
}

.MuiPaper-root {
  margin-top: 5px;
  border-radius: var(--radius6);
}

.container {
  margin-left: auto;
  position: relative;
  /* width: 33%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-warp: warp; */
  flex-wrap: wrap;
  padding: 30px;
}

.container .card {
  position: relative;
  max-width: 300px;
  height: 215px;
  background-color: #fff;
  margin: 30px 10px;
  padding: 20px 15px;

  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
  border-radius: 15px;
}
.container .card:hover {
  height: 360px;
}

.container .card .image {
  position: relative;
  width: 260px;
  height: 260px;

  top: -40%;
  left: 8px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.container .card .image {
  max-width: 100%;
  border-radius: 15px;
}

.container .card .content {
  position: relative;
  /* top : -140px; */
  padding: 10px 15px;
  color: #111;
  text-align: center;

  visibility: visible;
  /* opacity : 0; */
  transition: 0.3s ease-in-out;
}

.container .card:hover .content {
  margin-top: 30px;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
}

.card:hover .card {
  height: 300px;
}

.desc-content {
  position: relative;
  /* top : -140px; */
  height: 200px;
  max-height: 200px;
  padding: 10px 15px;
  color: #111;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card:hover .desc-content {
  max-height: 200px;
  max-width: 260px;
  margin-top: 10px;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
}
.desc-content-scroll {
  overflow-y: auto;
  max-height: 60px;
  padding: 2px;
}
.desc-content-pera {
  font-size: 15px;
  margin-top: 5px;
  text-align: left;
}
.notary-box-heading {
  text-align: left;
}

.ck-editor__editable {
  min-height: 120px;
}
