.admin-main {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background-color: var(--background);
}

.admin-main .admin-main-left {
    width: 100%;
    max-width: 290px;
    flex: 0 0 290px;
    transition: 0.5s all ease-in-out;
}

.admin-main .admin-main-left .admin-main-sidebar {
    background-color: var(--primary);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 290px;
    transition: 0.5s all ease-in-out;
    z-index: 1;
}

.admin-main .admin-main-left.deactive {
    max-width: 0;
}

.admin-main .admin-main-left.deactive .admin-main-sidebar {
    left: -290px;
}

.admin-main .company-logo {
    width: 100%;
    max-width: 100px;
    height: auto;
}

.admin-main .admin-sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 66px;
    padding: 8px 16px;
    /* box-shadow: var(--shadow-md); */
    /* border-bottom: 1px solid rgb(247 250 254 / 20%); */
}

.admin-main .admin-sidebar-logo button {
    display: none;
}

.admin-main .admin-sidebar-list {
    height: calc(100vh - 66px - 16px);
    overflow: auto;
    padding: 8px;
    margin: 8px;
}

.admin-main .admin-sidebar-list-inner {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
}

.admin-main .admin-sidebar-list-inner .admin-sidebar-listitem{
    padding: 0;
}

.admin-main .admin-sidebar-list .sidebar-link {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    padding: 12px;
    width: 100%;
}

.admin-main .admin-sidebar-list .sidebar-link svg {
    width: 20px;
    height: 20px;
}

.admin-main .admin-sidebar-list .sidebar-link svg path {
    fill: #fff;
}

.admin-main .admin-sidebar-list .sidebar-link.active {
    background: linear-gradient(45deg, #3e53a0, transparent);
    border-radius: 4px;
}

.admin-main .admin-main-right {
    flex: 1;
    /* position: relative;  */
}

.admin-main .admin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    height: 66px;
    padding: 10px 16px;
    background-color: var(--background);
    position: sticky;
    left: 0;
    top: 0;
    box-shadow: var(--shadow-md);
}

.admin-main .nav-menu-btn{
    z-index: 1;
}

.admin-main .nav-menu-btn .line {
    fill: none;
    stroke: black;
    stroke-width: 8;
    transition: stroke-dasharray 1000ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1);
}

.admin-main .nav-menu-btn .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 8;
}

.admin-main .nav-menu-btn .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 8;
}

.admin-main .nav-menu-btn .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 8;
}

.admin-main .active .nav-menu-btn .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 8;
}

.admin-main .active .nav-menu-btn .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 8;
}

.admin-main .active .nav-menu-btn .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 8;
}

.admin-main .admin-header .admin-header-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.admin-main .admin-header .admin-header-right {
    display: flex;
    align-items: center;
    gap: 20px;
}

.admin-main .admin-header .admin-header-left .company-logo {
    opacity: 0;
    transition: 0.3s all ease-in-out;
}

.admin-main .deactive .admin-header .admin-header-left .company-logo {
    opacity: 1;
}

.admin-main .admin-header .admin-header-right .profile-drop-btn {
    background-color: #fff;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: var(--shadow-sm);
}

.admin-main .admin-content-main{
    height: calc(100vh - 66px);
    overflow: auto;
    padding: 20px 16px;
}