.user-otp-main .auth-otp-main {
  /* display: grid; */
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 30px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.user-otp-main .auth-otp-main::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  background-color: var(--primary);
  opacity: 0.1;
  z-index: -1;
  width: 450px;
  height: 450px;
  left: -100px;
  top: -50px;
}

.user-otp-main .auth-otp-main::after {
  position: absolute;
  content: "";
  border-radius: 50%;
  background-color: var(--primary);
  opacity: 0.1;
  z-index: -1;
  width: 300px;
  height: 300px;
  right: -100px;
  bottom: 50px;
}

/* .user-otp-main .auth-otp-main .auth-otp-left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 50px 15px;
} */

.user-otp-main .auth-otp-main .auth-otp-left .company-logo {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.user-otp-main .auth-otp-main .auth-otp-right {
  width: 100%;
  overflow: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
}

.user-otp-main .auth-otp-main .auth-otp-common-box {
  padding: 20px;
  border-radius: var(--radius6);
  box-shadow: var(--shadow-sm);
  background-color: #fff;
  width: 100%;
  max-width: 38%;
}

.user-otp-main .auth-otp-main .auth-otp-header {
  margin-bottom: 30px;
}

.user-otp-main .auth-otp-main .auth-otp-header .auth-otp-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 5px;
}

.user-otp-main .auth-otp-main .auth-otp-header .auth-otp-subtitle {
  color: #989898;
  font-size: 14px;
  line-height: 18px;
}

.user-otp-main .auth-otp-link {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--primary);
}

.user-otp-main .auth-otp-link.forgot-password {
  justify-content: flex-end;
}

.user-otp-main .auth-otp-btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.user-otp-main .form-group .form-input.otp-input-box {
  justify-content: space-between;
  gap: 15px;
}

.user-otp-main .form-group .form-input.otp-input-box input {
  width: 100%;
  border: 2px solid var(--secondary);
  text-align: center;
  font-family: inherit;
}

.user-otp-main .form-group .form-input.otp-input-box input:focus,
.user-otp-main .form-group .form-input.otp-input-box input:focus-visible {
  border: 2px solid var(--primary);
  outline: none;
}

/* register */
.dont-modal-para {
  color: #454545;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.auth-redirect-link {
  color: #0f5288;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  line-height: 20px;
}

.auth-otp-btn-box .register-btn {
  color: white;
  background-color: #27447c;
  padding: 10px 20px;
}

.auth-otp-btn-box .register-btn:hover {
  background-color: #27447c;
}

.error-text {
  color: red;
  font-size: 12px;
  line-height: 15px;
}

.auth-otp-common-box .auth-body {
  max-height: 500px;
  overflow: auto;
  margin-right: -15px;
  padding-right: 15px;
}

/* / start dropdown css / */

.user-dropdown-box .user-form-control {
  width: 100%;
  position: relative;
}

.user-dropdown-select .MuiSelect-select {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  padding: 9px 15px;
  border-radius: 4px;
  border: 1px solid var(--secondary);
}

.user-grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.user-dropdown-box .MuiMenu-list .user-drop-menuitem {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.user-dropdown-select .MuiOutlinedInput-notchedOutline {
  border-color: var(--secondary);
}

/* / end dropdown css / */
.auth-body .radio-group-main {
  margin-bottom: 10px;
}

.user-otp-main .auth-otp-main .register-common-box {
  max-width: 40%;
}

.verify-otp-button {
  padding: 11px 37px !important;
  background-color: #1e548e !important;
  color: #fff !important;
  border: none !important;
  border-radius: 7px !important;
  cursor: pointer !important;
}

.verify-otp-box {
  padding: 8px 16px !important;
  background-color: #ffffff00 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 7px !important;
  cursor: pointer !important;
}

.verify-otp-button:hover {
  background-color: #0056b3 !important;
}