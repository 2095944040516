*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.notary-document-main .notary-common-header .header-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}
.user-notary-table .table-th{
    font-size: 15px;
    font-weight: 700;
}
.user-notary-download-icon{
    color: green;
    cursor: pointer;
}