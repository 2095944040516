.user-contact-main .auth-contact-main {
  /* display: grid; */
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 30px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.user-contact-main .auth-contact-main::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  background-color: var(--primary);
  opacity: 0.1;
  z-index: -1;
  width: 450px;
  height: 450px;
  left: -100px;
  top: -50px;
}

.user-contact-main .auth-contact-main::after {
  position: absolute;
  content: "";
  border-radius: 50%;
  background-color: var(--primary);
  opacity: 0.1;
  z-index: -1;
  width: 300px;
  height: 300px;
  right: -100px;
  bottom: 50px;
}

/* .user-contact-main .auth-contact-main .auth-contact-left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 50px 15px;
} */

.user-contact-main .auth-contact-main .auth-contact-left .company-logo {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.user-contact-main .auth-contact-main .auth-contact-right {
  width: 100%;
  overflow: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
}

.user-contact-main .auth-contact-main .auth-contact-common-box {
  padding: 20px;
  border-radius: var(--radius6);
  box-shadow: var(--shadow-sm);
  background-color: #fff;
  width: 100%;
  max-width: 76%;
}

.user-contact-main .auth-contact-main .auth-contact-header {
  margin-bottom: 30px;
}

.user-contact-main .auth-contact-main .auth-contact-header .auth-contact-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 5px;
}

.user-contact-main .auth-contact-main .auth-contact-header .auth-contact-subtitle {
  color: #989898;
  font-size: 14px;
  line-height: 18px;
}

.user-contact-main .auth-contact-link {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--primary);
}

.user-contact-main .auth-contact-link.forgot-password {
  justify-content: flex-end;
}

.user-contact-main .auth-contact-btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.user-contact-main .form-group .form-input.otp-input-box {
  justify-content: space-between;
  gap: 15px;
}

.user-contact-main .form-group .form-input.otp-input-box input {
  width: 100%;
  border: 2px solid var(--secondary);
  text-align: center;
  font-family: inherit;
}

.user-contact-main .form-group .form-input.otp-input-box input:focus,
.user-contact-main .form-group .form-input.otp-input-box input:focus-visible {
  border: 2px solid var(--primary);
  outline: none;
}

/* register */
.dont-modal-para {
  color: #454545;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.auth-redirect-link {
  color: #0f5288;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  line-height: 20px;
}

.auth-contact-btn-box .register-btn {
  color: white;
  background-color: #27447c;
  padding: 10px 20px;
}

.auth-contact-btn-box .register-btn:hover {
  background-color: #27447c;
}

.error-text {
  color: red;
  font-size: 12px;
  line-height: 15px;
}

.auth-contact-common-box .auth-body {
  max-height: 500px;
  overflow: auto;
  margin-right: -15px;
  padding-right: 15px;
}

/* / start dropdown css / */

.user-dropdown-box .user-form-control {
  width: 100%;
  position: relative;
}

.user-dropdown-select .MuiSelect-select {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  padding: 9px 15px;
  border-radius: 4px;
  border: 1px solid var(--secondary);
}

.user-grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.user-dropdown-box .MuiMenu-list .user-drop-menuitem {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.user-dropdown-select .MuiOutlinedInput-notchedOutline {
  border-color: var(--secondary);
}

/* / end dropdown css / */
.auth-body .radio-group-main {
  margin-bottom: 10px;
}

.user-contact-main .auth-contact-main .register-common-box {
  max-width: 40%;
}

/* Style for document box */


.serial-wise-contact {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.serial-wise-number {
  flex-grow: 1;
  font-weight: bold;
}





.user-card-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D1D5DB;
  border-radius: 24px;
  padding: 10px;
  position: relative;
}

.user-card-img-main {
  display: flex;
}

.card-img {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  object-fit: cover;
}

.card-data-flex {
  display: flex;
  margin: 10px 0px;
}

.card-data-flex .card-user-lable {
  font-size: 15px;
  line-height: 18px;
  color: #111827;
  font-family: 'Poppins-Medium';
  max-width: 200px;
  margin-right: 10px;
}

.card-data-flex .card-user-data {
  font-size: 15px;
  line-height: 18px;
  color: #6B7280;
  font-family: 'Poppins-Regular';
}

.user-user-list-row {
  margin: 30px 0px;
}

.user-card-box .card-user-title {
  color: #114A65;
  font-size: 20px;
  line-height: 22px;
  font-family: 'Poppins-Medium';
  margin: 0px 0px 5px;
}

.user-card-flex {
  display: flex;
  align-items: center;
}

.card-containt-main {
  margin-left: 20px;
}

.user-list-row {
  margin-bottom: 50px;
}

.card-data-flex:last-child {
  margin-bottom: 0px;
}

.card-delete-icon {
  position: absolute;
  right: 6px;
  top: 6px;
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.card-pagination-main {
  margin-right: 0px;
}

.card-editemployee-icon {
  position: absolute !important;
  right: 6px !important;
  top: 6px !important;
  height: 15px !important;
  width: 15px !important;
  object-fit: contain !important;
}

.verify-contact-button {
  padding: 8px 16px !important;
  background-color: #1e548e !important;
  color: #fff !important;
  border: none !important;
  border-radius: 7px !important;
  cursor: pointer !important;
}

.verify-contact-button:hover {
  background-color: #0056b3 !important;
  /* Adjust the hover background color as needed */
}