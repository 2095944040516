@media (max-width: 1023px) {
    .admin-main .admin-main-left {
        width: unset;
        max-width: unset;
    }

    .admin-main .admin-sidebar-logo {
        justify-content: space-between;
    }

    .admin-main .admin-sidebar-logo button {
        display: block;
    }

    .admin-main .admin-sidebar-logo button path {
        fill: #fff;
    }

    .admin-main .admin-header .admin-header-left .company-logo {
        opacity: 1;
    }

    .admin-main .nav-menu-btn:after {
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        background-color: rgba(0, 0, 0, .2);
        content: "";
        height: 100%;
        opacity: 0;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 0;
        transition: all .3s ease-in-out;
        visibility: hidden;
        width: 100%;
        z-index: 3;
    }

    .admin-main .active .nav-menu-btn:after {
        opacity: 1;
        overflow: auto;
        visibility: visible;
    }
}

@media (max-width: 549px) {
    .admin-main .admin-main-left .admin-main-sidebar {
        max-width: 250px;
    }
}