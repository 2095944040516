.admin-main .dashboard-main .total-counter-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-bottom: 30px;
}

.admin-main .dashboard-main .total-counter-box .total-counter-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 15px;
    border-radius: var(--radius6);
    box-shadow: var(--shadow-sm);
    background-color: #fff;
}

.admin-main .dashboard-main .total-counter-item .counter-title {
    font-size: 12px;
    line-height: 16px;
    color: #989898;
    margin-bottom: 5px;
}

.admin-main .dashboard-main .total-counter-item .counter-number {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
}

.admin-main .dashboard-main .total-counter-item .counter-item-right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #e4e4e4;
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
}

.admin-main .dashboard-main .counter-item-right svg {
    width: 30px;
    height: 30px;
}